export interface WlantsInfoData {
    identity: {
        id: number;
        email: string;
        status: 'ENABLED' | 'DISABLED' | 'PENDING';
        fkProductId: number;
        isAccountExpired: boolean;
    };
    availableTools: Array<string>;
}

export class WlantsInfo {
    protected data: WlantsInfoData;

    constructor(data: WlantsInfoData) {
        this.data = data;
    }

    public getData(): WlantsInfoData {
        return this.data;
    }

    public getAvailableTools(): Array<string> {
        if (!this.data) {
            return [];
        }
        return this.data.availableTools;
    }

    public isModuleEnabled(module: string): boolean {
        return (window as any)._enabledModules.indexOf(module) > -1;
    }

    public isLogged(): boolean {
        // return WlantsCookie.getCookie('_authorized') === '1';
        return this.data && typeof this.data.identity !== 'undefined';
    }

    public isAccountExpired(): boolean {
        if (!this.data) {
            return true;
        }
        return this.data.identity.isAccountExpired;
    }

    public hasProduct(): boolean {
        if (!this.data) {
            return true;
        }
        return !!this.data.identity.fkProductId;
    }

    public isToolsHome(): boolean {
        return window.location.href.indexOf('member/home') > -1;
    }
}
