export class WlantsCookie {
    public static getCookie(cname): string {
        let name: string = cname + '=';
        let decodedCookie: string = decodeURIComponent(document.cookie);
        let ca: Array<string> = decodedCookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return '';
    }
}
