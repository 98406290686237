import {WlantsInfo} from './WlantsInfo';
import {WlantsCookie} from './WlantsCookie';

export class ProtectedContent {
    protected isLogged: boolean;
    protected productId: number;
    protected isDemoEnabled: boolean;
    protected elements: Array<any> = [];
    protected info: WlantsInfo;

    constructor(info: WlantsInfo) {
        this.info = info;
        this.isLogged = this.info.isLogged();
        this.productId = Number(WlantsCookie.getCookie('_product_id'));
        this.isDemoEnabled = this.info.isModuleEnabled('WlantsDemo');
    }

    public run(): void {
        this.findElementsByClassName('tools-index-page');
        this.findElementsByClassName('main-tools');
        this.findElementsByClassName('protected-content');

        if (this.isLogged || this.isDemoEnabled) {
            this.showProtectedElements();
            if (this.productId) {
                this.updateToolLinks();
            }
        }
    }

    protected updateToolLinks(): void {
        let els = document.getElementsByClassName('tool-link');
        for (let i in els) {
            if (els.hasOwnProperty(i)) {
                (els[i] as any).href += '?p=' + this.productId;
            }
        }
    }

    protected showProtectedElements(): void {
        for (let element of this.elements) {
            element.style.display = 'block';
        }
    }

    protected findElementsByClassName(className): void {
        let els = document.getElementsByClassName(className);
        for (let i in els) {
            if (els.hasOwnProperty(i)) {
                this.elements.push(els[i]);
            }
        }
    }
}
