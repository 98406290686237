import * as $ from 'jquery';
import {WlantsInfo, WlantsInfoData} from './WlantsInfo';
import {WlantsCookie} from './WlantsCookie';
import {ProtectedContent} from './ProtectedContent';
import {CookiesInfo} from './CookiesInfo';
import {AntsPush} from './AntsPush';

export class Wlants {
    protected pingDone: boolean = false;
    protected info: WlantsInfo;

    public run(): void {
        (window as any).getCookie = (name: string): string => {
            return WlantsCookie.getCookie(name);
        };

        if ((window as any)._enabledModules.indexOf('AntsPush') > -1) {

        }

        this.ping().then((data: WlantsInfoData) => {
            this.info = new WlantsInfo(data);
            this.pingDone = true;

            if (this.info.isLogged()) {
                new AntsPush().run();
            }
        });

        this.wait().then(() => {
            this.checkGeneralContent();

            if (this.checkToolsHome()) {
                return;
            }

            if (this.checkPagesOnlyForGuest()) {
                return;
            }

            new ProtectedContent(this.info).run();

            this.checkTools();

            this.checkSingleSessionLogout();

            if (this.info.isLogged()) {
                $('.wl-show-logged').addClass('wl-show');
            } else {
                $('.wl-show-not-logged').addClass('wl-show');
            }
        });
    }

    protected wait(): Promise<void> {
        return new Promise<void>(resolve => {
            let interval = setInterval(() => {
                if ((window as any)._chld === true && this.pingDone) {
                    resolve();
                    clearInterval(interval);
                }
            }, 100);
        });
    }

    protected checkGeneralContent(): void {
        $('body').on('click', '.nav-link', function () {
            $(this).closest('.nav-tabs').find('.nav-link').removeClass('active');
            $(this).addClass('active');
        });
        $(function () {
            $('.pricing-plans-accordion .accordion-item.is-active').children('.accordion-panel').slideDown();
            $('.pricing-plans-accordion .accordion-item').on('click', function () {
                $(this).siblings('.accordion-item').removeClass('is-active').children('.accordion-panel').slideUp();
                $(this).toggleClass('is-active').children('.accordion-panel').slideToggle('ease-out');
            });
        });
        $('.pricing-plan-convert-confirm').show();
        $('.fire-payment-cancel').on('click', function (e) {
            if (!confirm('Are you sure?')) {
                e.preventDefault();
            }
        });
        $('.toggle-nav').on('click', function () {
            $(this).toggleClass('active');
            $('#main-navigation').toggleClass('show');
            $('body').toggleClass('nav-opened');
        });
        $('#main-navigation.show .navigation-menu a.toggle-submenu').click(function (e) {
            e.preventDefault();
            $(this).next('ul.submenu').toggleClass('open-subnav');
        });
        $('.sidebar-toggle').on('click', function () {
            $('body').toggleClass('sidebar-opened');
            $('.left-sidebar').toggleClass('sidebar-wider');
        });
        if ($('.main-content > .with-sidebar').length != 0) {
            $('.tools-header-inside').addClass('tools-page-header');
        }

        new CookiesInfo().init();
    }

    protected checkPagesOnlyForGuest(): boolean {
        if (
            this.info.isLogged()
            &&
            (
                window.location.href.indexOf('member/login') > -1
                || window.location.href.indexOf('member/forgot-password') > -1
                || window.location.href.indexOf('member/register') > -1
            )
        ) {
            window.location.href = '/member/login';
            return true;
        }
        return false;
    }

    protected checkToolsHome(): boolean {
        if (
            this.info.isToolsHome()
            && this.info.isModuleEnabled('WlantsPayment')
            && this.info.isLogged()
            && (
                this.info.isAccountExpired() || !this.info.hasProduct()
            )
        ) {
            window.location.href = '/pricing';
            return true;
        }
        return false;
    }

    protected checkTools(): void {
        if (this.info.isModuleEnabled('WlantsDemo')) {
            $('.tool-block, .tools-nav li').show();
        } else {
            for (let tool of this.info.getAvailableTools()) {
                $('*[data-id="' + tool + '"]').show();
            }
        }
    }

    protected checkSingleSessionLogout(): void {
        if (WlantsCookie.getCookie('_ss_logout') === '1') {
            document.cookie = '_ss_logout=0;0;path=/';
            let els = document.getElementsByClassName('ss-logout-message');
            for (let i in els) {
                if (els.hasOwnProperty(i)) {
                    (els[i] as any).style.display = 'block';
                }
            }
        }
    }

    protected ping(): Promise<WlantsInfoData> {
        return new Promise<WlantsInfoData>(resolve => {
            $.ajax({
                url: '/member-api/ping',
                complete: (r) => {
                    resolve(r.responseJSON.data);
                },
            });
        });
    }
}
