import * as $ from 'jquery';
import * as jsc from 'js-cookie';

export class CookiesInfo {
    public init() {
        if (!Number(jsc.get('cinfo'))) {
            $('.cookie-popup').show();
            $('.cookie-popup .cookie-ok').on('click', () => {
                jsc.set('cinfo', '1');
                $('.cookie-popup').hide();
            });
        }
    }
}
