import * as $ from 'jquery';

declare const OneSignal: any;

export class AntsPush {
    public run(): void {
        if ((window as any)._enabledModules.indexOf('AntsPush') < 0) {
            return;
        }

        $.ajax({
            url: '/member-api/push-user-settings',
            type: 'get',
            complete: (r) => {
                if (r.status === 200) {
                    let enabled: boolean = r.responseJSON.enabled;
                    if (enabled !== false) {
                        console.log('push not disabled');
                        this.initOneSignal();
                    } else {
                        console.log('push disabled');
                    }
                }
            },
        });

        $.ajax({
            url: '/member-api/push-message',
            type: 'GET',
            complete: (r) => {
                if (r.status === 200) {
                    let count: number = 0;
                    for (let m of r.responseJSON._embedded.pushMessage) {
                        if (!m._embedded.seen) {
                            count++;
                        }
                    }
                    if (count > 0) {
                        this.waitElement('#top-nav-user-profile').then(() => {
                            $('#top-nav-user-profile').append(`<span class="counter">${count}</span>`);
                        });
                        this.waitElement('#ants-push-user-messages-nav-item').then(() => {
                            $('#ants-push-user-messages-nav-item').append(`<span class="counter">${count}</span>`);
                        });
                    }
                }
            },
        });
    }

    protected waitElement(selector: string): Promise<void> {
        return new Promise<void>((resolve) => {
            let interval = setInterval(() => {
                if ($(selector).length > 0) {
                    clearInterval(interval);
                    resolve();
                }
            }, 400);
        });
    }

    protected onUserId(userId: string): void {
        if (!userId) {
            return;
        }
        $.ajax({
            url: '/member-api/push-external-id',
            method: 'POST',
            data: {
                externalId: userId,
            },
            complete: (r) => {
                console.log('OneSignal - ajax complete', r);
            },
        });
    }

    protected initOneSignal(): void {
        (window as any).OneSignal = (window as any).OneSignal || [];

        OneSignal.push(() => {
            OneSignal.init({
                appId: (window as any)._oneSignalAppId,
                autoResubscribe: true,
            });
        });

        OneSignal.push(() => {
            OneSignal.getUserId().then((userId) => {
                console.log('OneSignal - userId (1)', userId);
                this.onUserId(userId);
                OneSignal.push(['getNotificationPermission', function (permission) {
                    console.log('Site Notification Permission:', permission);
                    OneSignal.isPushNotificationsEnabled(function (isEnabled) {
                        if (isEnabled) {
                            console.log('Push notifications are enabled!');
                        } else {
                            console.log('Push notifications are not enabled yet.');
                            OneSignal.showNativePrompt();
                        }
                    });
                }]);
            });
        });

        OneSignal.push(() => {
            OneSignal.on('subscriptionChange', (isSubscribed) => {
                console.log('OneSignal - isSubscribed', isSubscribed);
                if (isSubscribed) {
                    OneSignal.getUserId().then((userId) => {
                        console.log('OneSignal - userId (2)', userId);
                        this.onUserId(userId);
                    });
                }
            });
        });

    }
}
